import React from 'react';
import { connect } from 'react-redux';

import { Button, SearchInput, DataTable, Pager, Dialog, Bubble } from 'tyb';

const { loadCodePrintingConfigList, loadCodeParamsDetail, setCurrentStep, setEditStep, setCreateStep, cancelverifyCodeTemplateList, submitCancelverifyCodeTemplateList } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { domainConfigsByCodeSourceType } = iceStarkData.store.get('commonAction')?.domainConfigs;
const { updateCodeParamsDetailDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
import { formatDate } from 'ucode-utils';
import './index.scss';
import enums from '@/assets/enum.json';
import CancelverifyCode from './CancelverifyCode.js';

@connect(
    state => ({
        loading: state.codePrintingConfig.loadCodePrintingConfigListLoading,
        codePrintingConfigList: state.codePrintingConfig.codePrintingConfigList,
        codeParamsDetail: state.codePrintingConfig.codeParamsDetail,
        codeParamsDetailDialogStatus: state.ui.codeParamsDetailDialogStatus,
        permissionIds: state.users.permissionIds || [],
        domainConfigsByEseIdSelect: state.domainConfigs.domainConfigsByCodeSourceTypeSelect,   // 码域名下拉框
        cancelverifyCodeTemplateListSelect: state.codePrintingConfig.cancelverifyCodeTemplateListSelect   // 核销码配置下拉框内容

    }),
    {
        loadCodePrintingConfigList: loadCodePrintingConfigList.REQUEST,
        loadCodeParamsDetail: loadCodeParamsDetail.REQUEST,
        updateCodeParamsDetailDialogStatus,
        setCurrentStep,
        setEditStep,
        setCreateStep,
        domainConfigsByEseId: domainConfigsByCodeSourceType.REQUEST,   // 获取码域名接口
        getCancelverifyCodeTemplateList: cancelverifyCodeTemplateList.REQUEST,   // 获取核销码配置接口

    }
)
class CodePrintingConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCancelVerifyCodeDialogStatus: false,   // 控制新增核销码规格弹窗显影的条件
            tableColumns: this.getTabColumns(),
            perPageCountList: [20, 50, 100],
            params: {
                idOrName: '',
                page: 0,
                size: 20,
                sort: ''
            },
            passwordComplexity: Object.keys(enums.passwordComplexity).map(item => { return { value: item, text: enums.passwordComplexity[item].name } }),

        }
        console.log(this.state.passwordComplexity)
    }

    componentDidMount() {
        this.props.loadCodePrintingConfigList({});
        // 码域名传参写死为核销码和ENABLE
        this.props.domainConfigsByEseId({ codeSourceType: 'WRITE_OFF_CODE', status: 'ENABLE' });  
        // 测试调用核销码配置接口
        this.props.getCancelverifyCodeTemplateList();        
    }

    changeSearchBox = (searchText) => {
        const params = this.state.params;
        params.idOrName = searchText
        this.setState({
            params
        })
    }

    search = () => {
        const params = this.state.params;
        params.page = 0;
        this.props.loadCodePrintingConfigList(params);
    }

    handleCreate = (zuhe) => {

        this.props.setCurrentStep(0)


        this.props.setCreateStep({ step: 0, values: zuhe ? { combinationTagType: 'SAME_LEVEL_TAG', conditionKeys: '2', batchs: '1', multiCodeAsc: false } : {} })
        this.props.setCreateStep({
            step: 1, values: {
                passwordComplexity: 'HIGHLY_SECRECY',
                printFileFormat: 'CSV',
                registrationMethod: 'ONLINE_INPUT',
            }
        })
        this.props.setCreateStep({
            step: 2, values: {
                configWorkflowTemplateDTOs: [{
                    workflowNodes: [
                        {
                            nodeCategory: 'USER',
                            nodeType: 'PROCESS_START',
                        },
                        {
                            nodeCategory: 'USER',
                            nodeType: 'PROCESS_AUDIT',
                        },
                        {
                            nodeCategory: 'USER',
                            nodeType: 'PACKET_RECEIVER',
                        },
                        {
                            nodeCategory: 'USER',
                            nodeType: 'PASSWORD_RECEIVER',
                        },
                    ]
                }]
            }
        })
        this.props.setCreateStep({
            step: 3, values: {
                genNoactiveWntime: 720,
                regNoactiveWntime: 15,
                downloadNoactiveWntime: 72,
                ipcNoDownloadWntime: 2
            }
        })

        if (zuhe === true) {
            this.props.history.push('/codetagConfig/createCompose');
        }
        else if(zuhe === false) {
            this.props.history.push('/codetagConfig/create');
        }
        else if (zuhe === 'cancelverify') {
            // titleCancelVerifyCode：控制新增/编辑核销码弹窗的标题
            // cancelverifyInfo：用于给form传数据，当点击按钮的时候传个空对象即可达到清空表单的效果
            this.setState({ titleCancelVerifyCode: 'add', openCancelVerifyCodeDialogStatus: true, cancelverifyInfo: {} });
        }



    }
    handleEdit = (record) => {

        this.props.setEditStep({ step: 1, values: '' })
        this.props.setEditStep({ step: 2, values: '' })
        this.props.setEditStep({ step: 3, values: '' })

        if (record.combinationTagType == "SINGLE_TAG") {
            this.props.history.push(`/codetagConfig/edit?action=edit&id=${record.id}&step=0`);
        } else {
            this.props.history.push(`/codetagConfig/editCompose?action=edit&id=${record.id}&step=0`);
        }
    }
    nameList = (record) => {
        let name = ''
        if (record && record.codeSources && record.codeSources.length > 0 && record.codeSources != [null]) {
            record.codeSources.forEach(it => { if (it && it.name && it.name.length > 0) { name = (name ? (name + ',') : '') + it.name } })
        }
        // return name ? name : '标准码'
        return name ? name : (record.writeOff ? '核销码' : '标准码')
    }
    getTabColumns = () => {
        const { permissionIds } = this.props;
        return [
            {
                key: 'id',
                dataIndex: 'id',
                title: '码印刷规格名称/ID',
                render: (value, record) => <div>
                    <div title={record.name}><span className="text-overflow">{record.name}</span></div>
                    <div title={value}><span className="text-overflow">{value}</span></div>
                </div>
            },
            {
                key: 'codeSource.name',
                dataIndex: 'codeSource.name',
                title: '码源',
                // render: (value) => <div title={value || '标准码'}>
                //     <span className="text-overflow">{value || '标准码'}</span>
                render: (value, record) => <div title={this.nameList(record)}>
                    <span className="text-overflow">{this.nameList(record)}</span>
                </div>

            },
            {
                key: 'combinationTagType',
                dataIndex: 'combinationTagType',
                title: '类型',
                render: (value) => <div title={enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}>
                    <span>{enums.combinationTagType[value] ? enums.combinationTagType[value].name : ''}</span>
                </div>
            },
            {
                key: 'passwordComplexity',
                dataIndex: 'passwordComplexity',
                title: '加密方式',
                // render: (value) => <div>{(value=="GENERAL_SECRECY")?((value=="HIGHLY_SECRECY")?"中保密度":""):"高保密度"}</div>
                //     render: (value) => {
                //         return value
                //         console.log(value)
                // //    <span className="text-overflow"></span>
                //     // </div>
                //     }
                render: (value, record, index) =>
                    <div>
                        <span>{(value == 'GENERAL_SECRECY') ? "一般保密度" : (value == 'HIGHLY_SECRECY') ? "中保密度" : "高保密度"}</span>
                    </div>

            },
            {
                key: 'printFileFormat',
                dataIndex: 'printFileFormat',
                title: '文件格式',
            },
            {
                key: 'updatedTime',
                dataIndex: 'updatedTime',
                title: '最后修改时间',
                render: (value) => <div title={formatDate(value)}>
                    <span className="text-overflow">{formatDate(value)}</span>
                </div>
            },
            {
                key: 'op',
                dataIndex: 'op',
                title: '操作',
                render: (value, record) =>
                    <div className="table-operate-btn">
                        {
                            permissionIds.includes('code.tagConfig.edit') &&
                            <span onClick={() => {
                                // console.log(record, '点击编辑的record----', record.id, 'record.id-----');
                                // 根据码类型来控制“编辑”的分离，需要做非空判断，因为不是所有码规格都有
                                if (record.writeOff) {
                                    // 测试：点击编辑form里有内容
                                    // this.setState({ cancelverifyCodeId: record.id, titleCancelVerifyCode: 'edit',openCancelVerifyCodeDialogStatus: true, cancelverifyInfo: {name: '1111'} });

                                    // cancelverifyInfo：用于给form传数据
                                    this.setState({ cancelverifyCodeId: record.id, titleCancelVerifyCode: 'edit', openCancelVerifyCodeDialogStatus: true, cancelverifyInfo: record }); 
                                } else {
                                    this.handleEdit(record)
                                }
                            }}>编辑</span>
                        }
                        {/* <span onClick={this.viewCodeParamsDetail(record.id)}>查看码参数</span> */}
                        {/* 当码类型不是核销码的时候展示“查看码参数” */}
                        { !record.writeOff && <span onClick={this.viewCodeParamsDetail(record.id)}>查看码参数</span> }
                    </div>
            },
        ]
    }

    onPageChange = (val) => {
        const { params } = this.state;
        params.page = val;
        this.setState({ params });
        this.props.loadCodePrintingConfigList(params);
    }

    onPerPageChange = (val) => {
        const params = this.state.params;
        params.size = val;
        params.page = 0;
        this.setState({
            params
        });
        this.props.loadCodePrintingConfigList(params);
    }

    viewCodeParamsDetail = (id) => () => {
        this.props.loadCodeParamsDetail(id);
    }

    handleCancel = () => {
        this.props.updateCodeParamsDetailDialogStatus(false);
    }

    // 关闭核销码弹窗
    handleCancelCancelVerifyCode = () => {
        this.setState({ openCancelVerifyCodeDialogStatus: false });
    }    

    render() {
        const { params, tableColumns, perPageCountList, openCancelVerifyCodeDialogStatus } = this.state;
        const { codePrintingConfigList = {}, loading, codeParamsDetailDialogStatus, codeParamsDetail = {}, permissionIds } = this.props;
        const codePrintingConfigListLength = codePrintingConfigList.content ? codePrintingConfigList.content.length : 0
        return (
            <div className={`CodePrintingConfig ${codePrintingConfigListLength > 0 && !loading ? 'table-tr-height50' : 'table-tr-middle'}`}>
                <div className="search-bar">
                    <div className="tc-15-bubble-icon">
                        {
                            permissionIds.includes('code.tagConfig.add') &&
                            <div>
                                <Button onClick={() => this.handleCreate(false)} style={{ marginRight: 10 }}>新增码印刷规格</Button>
                                    <Button onClick={() => this.handleCreate(true)} className="weak-button">组合印刷规格</Button>
                                    {/* 迎驾贡注释按钮 */}
                                    {/* <Button onClick={() => this.handleCreate('cancelverify')} className="weak-button" style={{ marginLeft: 10 }}>新增核销码规格</Button> */}
                            </div>
                        }
                        {/* { codePrintingConfigList.totalElements >= 20 && <Bubble position="bottom">只可建20个码印刷规格</Bubble> } */}
                    </div>

                    <div className="search-input-block">
                        <SearchInput
                            onChange={this.changeSearchBox}
                            onSearch={this.search}
                            value={params.idOrName}
                            placeholder="输入码印刷规格名称/ID查询"
                        />
                    </div>
                </div>

                <DataTable
                    // bodyStyle={{ width: '100%', maxWidth: '100%' }}
                    columns={this.getTabColumns()}
                    data={codePrintingConfigList.content}
                    rowKey="id"
                    loading={loading}
                    messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                        if (data == null || data.length === 0) {

                            if (permissionIds.includes('code.tagConfig.add')) {
                                return <span className="text-weak">暂无码印刷规格，您可以点击左上角<span onClick={() => this.handleCreate(false)}>新增码印刷规格</span></span>;
                            } else {
                                return <span className="text-weak">暂无数据</span>;
                            }
                        }
                    }}
                />

                {codePrintingConfigListLength > 0 && !loading ? <div className="page-padding20">
                    <Pager
                        total={codePrintingConfigList.totalElements}
                        current={params.page}
                        perPageCountList={perPageCountList}
                        perPageCount={params.size}
                        onPageChange={this.onPageChange}
                        onPerPageChange={this.onPerPageChange}
                    />
                </div> : ''}
                <Dialog
                    title="码参数详情"
                    className='macanshuDialog'
                    visible={codeParamsDetailDialogStatus}
                    onCancel={this.handleCancel}
                    style={{ width: '288px' }}
                    footerRender={() =>
                        <div className="tc-15-rich-dialog-ft-btn-wrap">
                            <Button className="weak-button" onClick={this.handleCancel}>关闭</Button>
                        </div>}
                >
                    <p style={{ fontSize: '12px' }}>{enums.combinationTagType[codeParamsDetail.combinationTagType] ? enums.combinationTagType[codeParamsDetail.combinationTagType].name : ''}
                        {codeParamsDetail.combinationTagType != 'SINGLE_TAG' ?
                            <span>
                                {codeParamsDetail.combinationTagType == 'MULTI_LEVEL_TAG' ?
                                    <span>
                                        1:{codeParamsDetail.combinationConfigDtos ? codeParamsDetail.combinationConfigDtos[1].count : 0}
                                    </span> : ''
                                }
                            </span> : ""
                        }
                    </p>
                    <div>

                        <div className='box' style={{ display: 'inline-block' }}>
                            <div className='box-head' >
                                <div>位置</div>
                                <div>域名</div>
                                <div>长度</div>
                            </div>
                            {codeParamsDetail.combinationTagType != 'SINGLE_TAG' ?
                                <div>
                                    {codeParamsDetail.combinationConfigDtos ? codeParamsDetail.combinationConfigDtos.map((item, index) =>
                                        <div className='box-body' key={index}>
                                            {codeParamsDetail.combinationTagType == 'MULTI_LEVEL_TAG' ?
                                                index == 0 ?
                                                    <div>箱码</div>
                                                    : codeParamsDetail.combinationConfigDtos.length == 2 ?
                                                        <div>瓶规格</div>
                                                        : <div>第{index}个码规格</div>
                                                : <div>第{index + 1}个码规格</div>}

                                            {item.enterpriseDomain && item.enterpriseDomain.codeSourceType !== 'WECHART_CODE' && <div>
                                                {item.enterpriseDomain ? `${item.enterpriseDomain.protocol}${item.enterpriseDomain.domain}/${item.enterpriseDomain.subDomain ? (item.enterpriseDomain.subDomain + '/') : ''}` : ''}
                                            </div>}
                                            {item.enterpriseDomain && item.enterpriseDomain.codeSourceType == 'WECHART_CODE' && <div></div>}
                                            {item.enterpriseDomain && item.enterpriseDomain.codeSourceType !== 'CODE_IMPORT' && <div>
                                                {item.historyTagTemplateConfig.length}
                                            </div>}
                                        </div>
                                    ) : ""}
                                </div> :
                                <div className='box-body' >
                                    <div>单码</div>
                                    {codeParamsDetail.enterpriseDomain && codeParamsDetail.enterpriseDomain.codeSourceType !== 'WECHART_CODE' ? <div>
                                        {codeParamsDetail.enterpriseDomain ? `${codeParamsDetail.enterpriseDomain.protocol}${codeParamsDetail.enterpriseDomain.domain}/${codeParamsDetail.enterpriseDomain.subDomain ? (codeParamsDetail.enterpriseDomain.subDomain + '/') : ''}` : ''}
                                    </div> : <div></div>}
                                    {codeParamsDetail.enterpriseDomain && codeParamsDetail.enterpriseDomain.codeSourceType !== 'CODE_IMPORT' ? <div >
                                        {codeParamsDetail.length}
                                    </div> : <div></div>}
                                </div>}
                        </div>
                    </div>





                </Dialog>

                {/* 核销码弹窗 */}
                {openCancelVerifyCodeDialogStatus && <CancelverifyCode
                    titleCancelVerifyCode={this.state.titleCancelVerifyCode}
                    domainConfigsByEseIdSelect={this.props.domainConfigsByEseIdSelect}
                    cancelverifyCodeTemplateListSelect={this.props.cancelverifyCodeTemplateListSelect}
                    cancelverifyInfo={this.state.cancelverifyInfo}
                    cancelverifyCodeId={this.state.cancelverifyCodeId}
                    params={params}   // 码印刷规格列表刷新需要的分页参数
                    cancel={this.handleCancelCancelVerifyCode}
                />
                }

            </div>
        )
    }
}

export default CodePrintingConfig;